// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_wrapper__hXRqs {
  width: 103px;
  display: flex;
  align-items: center;
}

.logo_logo__m9kO6 {
  display: block;
  width: 48px;
  height: 55px;
  vertical-align: middle;
  background-color: #fff;
}
@media (max-width: 768px) {
  .logo_logo__m9kO6 {
    margin: 0 auto;
  }
}

.logo_img__-F\\+IK {
  width: 103px;
}

.logo_text__Aw0pn {
  display: inline-block;
  width: 177px;
  height: 36px;
  margin: 20px 0 20px 10px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/blocks/Header/components/Logo/logo.module.scss"],"names":[],"mappings":"AACA;EACC,YAAA;EACA,aAAA;EACE,mBAAA;AAAH;;AAGA;EACC,cAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,sBAAA;AAAD;AACC;EAND;IAOC,cAAA;EAEC;AACF;;AAAA;EACC,YAAA;AAGD;;AAAA;EACC,qBAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,sBAAA;AAGD","sourcesContent":["// @import '../../../../App.scss';\n.wrapper {\n\twidth: 103px;\n\tdisplay: flex;\n  \talign-items: center;\n}\n\n.logo {\n display: block;\n width: 48px;\n height: 55px;\n vertical-align: middle;\n background-color: #fff;\n @media (max-width: 768px) {\n\tmargin: 0 auto;\n  }\n}\n.img {\n\twidth: 103px;\n\t\n}\n.text {\n\tdisplay: inline-block;\n\twidth: 177px;\n\theight: 36px;\n\tmargin: 20px 0 20px 10px;\n\tvertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `logo_wrapper__hXRqs`,
	"logo": `logo_logo__m9kO6`,
	"img": `logo_img__-F+IK`,
	"text": `logo_text__Aw0pn`
};
export default ___CSS_LOADER_EXPORT___;
