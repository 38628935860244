// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supportBtn_button__YsX4a {
  border-radius: 8px;
  border: 2px solid #E84514;
  color: #E84514;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 13px 30px;
  background-color: white;
}
.supportBtn_button__YsX4a:hover {
  background-color: #FEF5EE;
}
.supportBtn_button__YsX4a:active {
  background-color: #FEE9D6;
}`, "",{"version":3,"sources":["webpack://./src/components/SupportBtn/supportBtn.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;AACD;AAAC;EACC,yBAAA;AAEF;AAAC;EACC,yBAAA;AAEF","sourcesContent":[".button {\n\tborder-radius: 8px;\n\tborder: 2px solid #E84514;\n\tcolor:#E84514;\n\tcursor: pointer;\n\tfont-size: 14px;\n\tfont-weight: 600;\n\tpadding: 13px 30px;\n\tbackground-color: white;\n\t&:hover {\n\t\tbackground-color: #FEF5EE;\n\t}\n\t&:active {\n\t\tbackground-color: #FEE9D6;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `supportBtn_button__YsX4a`
};
export default ___CSS_LOADER_EXPORT___;
