// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.customButtons_buttons__gxIyI {
  position: absolute;
  bottom: 10px; /* Підлаштуйте це значення для розташування стрілок під слайдером */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.customButtons_buttons__gxIyI .customButtons_left__RyS-q,
.customButtons_buttons__gxIyI .customButtons_right__eN7oE {
  background-color: #722009; /* Темний червоний фон */
  border: none;
  border-radius: 50%; /* Кругла форма */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Легка тінь */
  color: #fff; /* Білий колір для іконки стрілки */
  font-size: 24px; /* Розмір іконки */
}
.customButtons_buttons__gxIyI .customButtons_left__RyS-q:hover,
.customButtons_buttons__gxIyI .customButtons_right__eN7oE:hover {
  background-color: #d73a15;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomButtons/customButtons.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACC,kBAAA;EACA,YAAA,EAAA,mEAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AAED;AADC;;EAEC,yBAAA,EAAA,wBAAA;EACA,YAAA;EACA,kBAAA,EAAA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,wCAAA,EAAA,eAAA;EACA,WAAA,EAAA,mCAAA;EACA,eAAA,EAAA,kBAAA;AAGF;AAFE;;EACC,yBAAA;AAKH","sourcesContent":[".buttons {\n\tposition: absolute;\n\tbottom: 10px; /* Підлаштуйте це значення для розташування стрілок під слайдером */\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tpadding: 0 20px;\n\t.left,\n\t.right {\n\t\tbackground-color: #722009; /* Темний червоний фон */\n\t\tborder: none;\n\t\tborder-radius: 50%; /* Кругла форма */\n\t\twidth: 40px;\n\t\theight: 40px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tcursor: pointer;\n\t\tbox-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Легка тінь */\n\t\tcolor: #fff; /* Білий колір для іконки стрілки */\n\t\tfont-size: 24px; /* Розмір іконки */\n\t\t&:hover {\n\t\t\tbackground-color: #d73a15;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `customButtons_buttons__gxIyI`,
	"left": `customButtons_left__RyS-q`,
	"right": `customButtons_right__eN7oE`
};
export default ___CSS_LOADER_EXPORT___;
